<template>
	<div>
		<!-- top image bar -->
		<Header :propsObj="page"></Header>

		<!-- at home - logo button - at school -->
		<HomeSchoolBar showText></HomeSchoolBar>

		<!-- introduction -->
		<Intro :title="page.intro_title" :content="page.intro_content"></Intro>

		<!-- frame layout -->
		<div class="container px-0">
			<div class="frameContainer"></div>
		</div>

		<!-- keep in touch -->
		<div class="container bg-light-pink mt-5 py-3">
			<div class="row">
				<div class="col-md-6 offset-md-3 text-center">
					<h3 class="fg-red">LATEN WE CONTACT HOUDEN!</h3>
					<p>Mis niets! Schrijf je hier in voor mijn tweemaandelijkse digitale nieuwsbrief.</p>
					<input v-model="email" type="text" placeholder="Mailadres..." />
					<button class="bg-apricot text-white" @click="sendMail">VERSTUUR</button>
				</div>
			</div>
		</div>

		<!-- social media buttons -->
		<div class="container">
			<div class="row">
				<div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
					<SocialMedia></SocialMedia>
				</div>
			</div>
		</div>

		<!-- footer  -->
		<Footer></Footer>
	</div>
</template>

<script>
import Vue from 'vue';

import Header from '@/components/Header';
import HomeSchoolBar from '@/components/HomeSchoolBar.vue';
import Intro from '@/components/Intro.vue';
import SocialMedia from '@/components/SocialMedia.vue';
import Footer from '@/components/Footer.vue';

import BtnImage from '@/components/BtnImage';
import BtnReferences from '@/components/BtnReferences';

import axios from 'axios';
import InfiniteGrid, { FrameLayout } from '@egjs/infinitegrid';

export default {
	data() {
		return {
			page: {},
			WIDTH: 768,
			frameLarge: false,
			resizing: false,
			ig: Object,
			instance: new Array(8),
			BASE_URL: '/php/cms.php',
			tiles: [],
			email: ''
		};
	},
	components: {
		Header,
		HomeSchoolBar,
		Intro,
		SocialMedia,
		Footer
	},

	// watch: {
	// 	$route(to, from) {
	// 		console.log(from.name, '=>', to.name, this.route);
	// 		let id = this.$store.getters.getPageId(this.route);
	// 		this.$store.dispatch('getQuarks', id);
	// 	}
	// },

	created: function() {
		// console.clear();
		var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
		var localISOTime = new Date(Date.now() - tzoffset)
			.toISOString()
			.slice(0, -5)
			.replace('T', ' ');
		// console.log('home', localISOTime);
		this.page = this.$store.getters.page('');
		// console.log('page', this.page);
	},

	mounted() {
		// console.log('mounted');
		if (window.innerWidth >= this.WIDTH) {
			this.frameLarge = true;
		} else {
			this.frameLarge = false;
		}

		// this.handleResize();
		this.createFrameLayout(this.frameLarge);

		this.ig.on('layoutComplete', e => {
			if (e.isLayout && !e.isAppend) {
				console.log('layout complete', e);
			}
		});

		window.addEventListener('resize', this.handleResize);
	},

	methods: {
		handleResize() {
			if (!this.resizing) {
				// prevent resizing when not finished yet
				this.resizing = true;

				if (window.innerWidth >= this.WIDTH) {
					if (!this.frameLarge) {
						console.log(window.innerWidth, window.innerHeight);
						this.frameLarge = true;
						this.ig.destroy();
						this.createFrameLayout(true);
					}
				} else if (window.innerWidth < this.WIDTH - 20) {
					if (this.frameLarge) {
						console.log(window.innerWidth, window.innerHeight);
						this.frameLarge = false;
						this.ig.destroy();
						this.createFrameLayout(false);
					}
				}

				this.resizing = false;
			}
		},
		createFrameLayout(largeFrame) {
			// console.log('create framelayout', this.resizing, largeFrame, largeFrame ? 'large' : 'small');

			let frame = [];
			if (largeFrame) {
				frame = [
					[1, 1, 1, 2, 2, 2, 4, 4, 4, 4, 4, 4],
					[1, 1, 1, 2, 2, 2, 4, 4, 4, 4, 4, 4],
					[1, 1, 1, 2, 2, 2, 4, 4, 4, 4, 4, 4],
					[3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4],
					[3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4],
					[3, 3, 3, 3, 3, 3, 5, 5, 5, 5, 5, 5],
					[6, 6, 6, 6, 6, 6, 7, 7, 7, 8, 8, 8],
					[6, 6, 6, 6, 6, 6, 7, 7, 7, 8, 8, 8],
					[6, 6, 6, 6, 6, 6, 7, 7, 7, 8, 8, 8]
				];
			} else {
				// for (let f = 1; f < 8; f++) {
				// 	let val = [f, f];
				// 	frame.push(val);
				// }
				frame = [
					[1, 1, 1, 1, 1, 1],
					[1, 1, 1, 1, 1, 1],
					[1, 1, 1, 1, 1, 1],
					[1, 1, 1, 1, 1, 1],
					[2, 2, 2, 2, 2, 2],
					[2, 2, 2, 2, 2, 2],
					[2, 2, 2, 2, 2, 2],
					[2, 2, 2, 2, 2, 2],
					[3, 3, 3, 3, 3, 3],
					[3, 3, 3, 3, 3, 3],
					[3, 3, 3, 3, 3, 3],
					[3, 3, 3, 3, 3, 3],
					[4, 4, 4, 4, 4, 4],
					[4, 4, 4, 4, 4, 4],
					[4, 4, 4, 4, 4, 4],
					[4, 4, 4, 4, 4, 4],
					[4, 4, 4, 4, 4, 4],
					[5, 5, 5, 5, 5, 5],
					[6, 6, 6, 6, 6, 6],
					[6, 6, 6, 6, 6, 6],
					[6, 6, 6, 6, 6, 6],
					[6, 6, 6, 6, 6, 6],
					[7, 7, 7, 7, 7, 7],
					[7, 7, 7, 7, 7, 7],
					[7, 7, 7, 7, 7, 7],
					[8, 8, 8, 8, 8, 8],
					[8, 8, 8, 8, 8, 8],
					[8, 8, 8, 8, 8, 8],
					[8, 8, 8, 8, 8, 8]
				];
				// frame = [
				// 	[1, 1, 1, 2, 2, 2],
				// 	[1, 1, 1, 2, 2, 2],
				// 	[1, 1, 1, 2, 2, 2],
				// 	[3, 3, 3, 3, 3, 3],
				// 	[3, 3, 3, 3, 3, 3],
				// 	[3, 3, 3, 3, 3, 3],

				// 	[4, 4, 4, 4, 4, 4],
				// 	[4, 4, 4, 4, 4, 4],
				// 	[4, 4, 4, 4, 4, 4],
				// 	[4, 4, 4, 4, 4, 4],
				// 	[4, 4, 4, 4, 4, 4],
				// 	[5, 5, 5, 5, 5, 5],

				// 	[6, 6, 6, 6, 6, 6],
				// 	[6, 6, 6, 6, 6, 6],
				// 	[6, 6, 6, 6, 6, 6],

				// 	[7, 7, 7, 8, 8, 8],
				// 	[7, 7, 7, 8, 8, 8],
				// 	[7, 7, 7, 8, 8, 8]
				// ];
			}

			let margin = 10;

			// var vm = this;
			this.ig = new InfiniteGrid('.frameContainer', {
				horizontal: false
			});
			let its = this.getTiles(8, frame);
		},

		getItem(template, options) {
			return template.replace(/\$\{([^\}]*)\}/g, function() {
				var replaceTarget = arguments[1];

				return options[replaceTarget];
			});
		},

		getTiles(length, frame) {
			this.tiles = [];
			let url = this.BASE_URL + '/tiles';

			axios
				.get(url)
				.then(response => {
					// console.log(response.data);
					this.tiles = response.data;

					var arr = [];
					var ComponentClass = Vue.extend(BtnImage);
					var ComponentClass2 = Vue.extend(BtnReferences);

					for (var i = 0; i < length; ++i) {
						if (i != 4) {
							this.instance[i] = new ComponentClass({
								propsData: {
									title: this.tiles[i].title,
									bgColor: this.tiles[i].bgcolor,
									content: this.tiles[i].content,
									image: this.tiles[i].image,
									link: this.tiles[i].link
								}
							});
							this.instance[i].$mount(); // pass nothing
							arr.push(this.instance[i].$el);
						} else if (i == 4) {
							this.instance[i] = new ComponentClass2();
							this.instance[i].$mount(); // pass nothing
							arr.push(this.instance[i].$el);
						} else {
							// get some random pictures
							var link = 'https://naver.github.io/egjs-infinitegrid/assets/';
							var template = '<div class="item"><img src="${link}image/${no}.jpg"></div>';
							arr.push(this.getItem(template, { no: (i % 60) + 1, link: link }));
						}
					}

					this.$nextTick(() => {
						this.ig.setLayout(FrameLayout, {
							margin: 10,
							frame: frame
						});
						this.ig.append(arr, 0, false);
					});
				})
				.catch(error => {
					console.log(error);
				});
		},

		sendMail() {
            // console.log(this.email);
			var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
			if (this.email.match(mailformat)) {
				axios
					.post('/php/sendmail.php', this.email)
					.then(result => {
                        alert('uw aanmelding is doorgestuurd');
						console.log(result);
					})
					.catch(error => {
						console.log(error);
					});
			} else {
				alert('U heeft een fout email adres ingevoerd');
			}
		}
	}
};
</script>

<style scoped>
/* button */
button {
	height: 30px;
	padding: 1px 15px;
}
button:focus {
	outline: 0;
}
</style>
